import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Wrapper from '../../layout/Wrapper';

export default function TempContact({ job }) {
  return (
    <Wrapper>
      <motion.div
        className="mt-5 md:mt-0 md:col-span-1 flex items-center justify-between max-w-3xl mx-auto"
        initial={{ x: 60, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 60, opacity: 0 }}
        transition={{ duration: 0.3, ease: 'easeOut' }}
      >
        <div className="text-lg font-semibold text-gray-800 max-w-xl text-center text-[22px]">
          <p>
            Have a question? Reach out and we'll respond promptly. While you're at it,
            subscribe to our newsletter for a curated mix of digital insights, trends,
            tips, and stories.
          </p>
          <div className="mt-3 flex items-center justify-center">
            <img src="/email.svg" alt="Email Icon" className="w-5 h-5 mr-2" />
            <a href="mailto:hr@dilig.net" className="text-blue-600">
              office@dilig.net
            </a>
          </div>
          <div className="mt-1 flex items-center justify-center">
            <img src="/email.svg" alt="Email Icon" className="w-5 h-5 mr-2" />
            <a href="mailto:office@dilig.net" className="text-blue-600">
              hr@dilig.net
            </a>
          </div>
        </div>
        <img
          src={job.image?.data?.attributes?.url}
          alt={job.image?.data?.attributes?.alternativeText}
          className="w-1/4 h-auto"
        />
      </motion.div>
    </Wrapper>
  );
}

TempContact.propTypes = {
  setTab: PropTypes.func,
  tabTitle: PropTypes.string,
  setTabTitle: PropTypes.func,
  defaultIndex: PropTypes.number,
  defaultPositionSelection: PropTypes.string,
};
