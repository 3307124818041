import React, { useEffect } from 'react';
import Animation_Diligent from '../assets/animation_diligent.webm';
import TempContact from '../components/shared/TempContact';
import useAnalytics from '../hooks/useAnalytics';
import PageLayout from '../layout/PageLayout';
import useDataApi from './../hooks/useDataApi';
import { strapiApiBuilder } from './../utils/strapiApiBuilder';

const strapiPopulate = [
  'BusinessInquiry',
  'BusinessInquiry.image',
  'ApplyPosition',
  'ApplyPosition.image',
  'SEO',
  'SEO.metaSocial',
  'SEO.metaImage',
  'SEO.metaSocial.image',
];

function ContactPage() {
  const strapi = strapiApiBuilder('w-home-page', strapiPopulate, '');

  const [{ data, isLoading }] = useDataApi(strapi);

  useEffect(() => {
    document.title = 'Contact Us';
  }, []);

  useAnalytics('Contact Us');

  if (isLoading) {
    return (
      <div className="z-50 w-full h-screen bg-white dark:bg-dg-primary-1700 overflow-hidden dark:text-white flex items-center justify-center text-3xl font-semibold">
        <video id="animation" width="540" height="540" autoPlay muted loop>
          <source src={Animation_Diligent} type="video/webm" />
          Loading...
        </video>
      </div>
    );
  } else if (data && !isLoading) {
    return (
      <PageLayout>
        <section id="contact" className="h-fit mt-[120px]">
          {data ? (
            <TempContact job={data.ApplyPosition} business={data.BusinessInquiry} />
          ) : null}
        </section>
      </PageLayout>
    );
  } else {
    return <></>;
  }
}

export default ContactPage;
